import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { Button, ExtLinks } from "../components/elements"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"

const Photography = () => {
  const data = useStaticQuery(graphql`
      query {
          dark: file(relativePath: { regex: "/photography-portfolio.jpeg/" }) {
              childImageSharp {
                  fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
#          light: file(relativePath: { regex: "/photography-portfolio-light.jpeg/" }) {
#              childImageSharp {
#                  fluid(maxWidth: 2000) {
#                      ...GatsbyImageSharpFluid
#                  }
#              }
#          }
      }
  `)
  return (
    <Layout>
      <SEO title="Example 5, Photography Portfolio" />

      <InfoPagesWrapper>
        <h1>Outside The Frame, Photography</h1>

        <p>
          {`I wanted a relaxing and sophisticated feel for this project, and it was important that the images themselves were showcased without any design
          distractions. The font choice here was key, along with a dark/light theme feature based off of the user's preference on device. I feel that the site presents the work in a wonderfully flattering way`}
        </p>
        <ExtLinks href="https://outsidetheframe.photography/" target="_blank" rel="noreferrer">
          Take Me There!
        </ExtLinks>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;
          img {
            object-fit: fill !important;
          }
        `}
      >
        <Img fluid={data.dark.childImageSharp.fluid} />
        {/*<Img fluid={data.light.childImageSharp.fluid} />*/}
      </div>
    </Layout>
  )
}

export default Photography
